import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Shahada = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="لَا إِلَٰهَ إِلَّا ٱللَّٰهُ" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          {/* <div className="shoyarightChomskyArticle" /> */}
        </Link>
        <div className="articleTitle" id="shahada">
          <p>لَا إِلَٰهَ إِلَّا ٱللَّٰهُ</p>
        </div>
        <div className="byLine">
          {/* <p> By: Lil Shoyaright! (Pub. Jun. 29, 2022) </p> */}
        </div>
        <div className="articleBody">
          <div className="ninetyNine">
            <br />
            <p>
              ٱلْرَّحْمَـانُ ٱلْرَّحِيْمُ <br />
              ٱلْمَلِكُ ٱلْقُدُّوسُ ٱلْسَّلَامُ
              <br /> ٱلْمُؤْمِنُ ٱلْمُهَيْمِنُ ٱلْعَزِيزُ
              <br />
              ٱلْجَبَّارُ
              <br /> ٱلْمُتَكَبِّرُ ٱلْخَالِقُ ٱلْبَارِئُ
              <br /> ٱلْقَهَّارُ ٱلْمُصَوِّ رُٱلْغَفَّارُ
              <br />
              ٱلْوَهَّابُ ٱلْرَّزَّاقُ
              <br /> ٱلْفَتَّاحُ
              <br />
              ٱلْعَلِيمُ
              <br />
              ٱلْقَابِض ٱلْبَاسِطُ
              <br /> ٱلْخَافِضُ ٱلْرَّافِعُ ٱلْمُعِزُّ
              <br /> ٱلْمُذِلُّ ٱلْسَّمِيعُ
              <br /> ٱلْبَصِيرُ ٱلْحَكَمُ
              <br />
              ٱلْعَدْلُ ٱلْلَّطِيفُ ٱلْخَبِيرُ ٱلْحَلِيمُ
              <br /> ٱلْعَظِيمُ ٱلْغَفُورُ ٱلْشَّكُورُ ٱلْعَلِيُّ ٱلْكَبِيرُ
              <br /> ٱلْحَفِيظُ ٱلْمُقِيتُ ٱلْحَسِيبُ
              <br /> ٱلْجَلِيلُ ٱلْكَرِيمُ
              <br />
              ٱلْرَّقِيبُ ٱلْمُجِيبُ
              <br /> ٱلْوَاسِعُ ٱلْحَكِيمُ ٱلْوَدُودُ
              <br />
              ٱلْمَجِيدُ ٱلْبَاعِثُ
              <br />
              ٱلْشَّهِيدُ ٱلْحَقُّ
              <br />
              ٱلْوَكِيلُ ٱلْقَوِيُّ ٱلْمَتِينُ ٱلْوَلِيُّ
              <br />
              ٱلْحَمِيدُ ٱلْمُحْصِيُ ٱلْمُبْدِئُ ٱلْمُعِيدُ ٱلْمُحْيِى
              ٱلْمُمِيتُ
              <br />
              ٱلْحَىُّ ٱلْقَيُّومُ
              <br />
              ٱلْوَاجِدُ ٱلْمَاجِدُ ٱلْوَاحِدُ
              <br />
              ٱلْأَحَد ٱلْصَّمَدُ
              <br />
              ٱلْقَادِرُ ٱلْمُقْتَدِرُ ٱلْمُقَدِّمُ ٱلْمُؤَخِّرُ
              <br />
              ٱلأَوَّلُ ٱلْآخِرُ
              <br />
              ٱلْظَّاهِرُ ٱلْبَاطِنُ ٱلْوَالِي
              <br />
              ٱلْمُتَعَالِي ٱلْبَرُّ ٱلْتَّوَّابُ ٱلْمُنْتَقِمُ
              <br /> ٱلْعَفُوُّ ٱلْرَّؤُفُ
              <br />
              ٱلْمُلْكُ ذُو ٱلْجَلَالِ وَٱلْإِكْرَامُ
              <br />
              ٱلْمُقْسِطُ ٱلْجَامِعُ ٱلْغَنيُّ ٱلْمُغْنِيُّ ٱلْمَانِعُ
              <br />
              ٱلْضَّارُ ٱلْنَّافِعُ
              <br />
              ٱلْنُّورُ
              <br />
              ٱلْهَادِي
              <br />
              ٱلْبَدِيعُ ٱلْبَاقِي ٱلْوَارِثُ ٱلْرَّشِيدُ
              <br />
              ٱلْصَّبُورُ
            </p>
          </div>
        </div>
        {/* <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div> */}
      </div>
    </div>
  )
}
export default Shahada
